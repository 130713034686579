<template>
  <b-card>
    <b-card-title>Photos</b-card-title>
    <b-card-sub-title class="mb-1">
      Good quality photos allow guests to get a better idea about the property.
      We suggest you to upload clear and great photos in relevant categories that represent
      all your property has to offer. These photos will be displayed on your property's page.
    </b-card-sub-title>
    <div class="pb-2">
      <p>Choose feature image and upload.</p>
      <b-form-file
        ref="feature-images-uploader"
        v-model="featured_picture"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        accept="image/jpeg, image/png, image/jpg"
        class="w-50"
        @input="showImageUploader(featured_picture, 'feature')"
      />
      <div class="mt-2">
        <div
          v-if="previewFeaturedPicture"
          class="image-list d-flex flex-wrap"
        >
          <div
            class="image-wrapper"
          >
            <b-img
              :src="previewFeaturedPicture"
              thumbnail
              fluid
            />
          </div>
        </div>
      </div>
    </div>
    <!--room image uploader section-->
    <div class="border-top pt-2">
      <p>Choose general photos and upload.</p>
      <b-form-file
        ref="general-images-uploader"
        v-model="pictures"
        placeholder="Choose files or drop it here..."
        drop-placeholder="Drop file here..."
        accept="image/jpeg, image/png, image/jpg"
        multiple
        :disabled="isBtnLoading"
        class="w-50"
        @input="showImageUploader(pictures, 'general')"
      />
      <div class="image-placeholder">
        <div
          v-if="previewPictures"
          class="room-images"
        >
          <div class="image-list d-flex flex-wrap">
            <draggable
              v-model="previewPictures"
              class="d-flex flex-wrap"
              @change="changeImageOrder($event,previewPictures)"
            >
              <div
                v-for="(picture, p) in previewPictures"
                :key="p"
                class="image-wrapper"
              >
                <feather-icon
                  :id="`update-image-popover-${p}`"
                  icon="EditIcon"
                  class="edit-icon"
                  :disabled="popOverIndex !== null ? true : null"
                  @click="openPopover(p)"
                />
                <feather-icon
                  icon="XIcon"
                  class="remove-icon"
                  @click="removePicture(picture, p)"
                />
                <b-img
                  :src="picture.img"
                  thumbnail
                  fluid
                />
              </div>
            </draggable>
          </div>
        </div>
        <b-popover
          ref="updateImagePopover"
          :target="`update-image-popover-${popOverIndex}`"
          triggers="click"
          placement="top"
          container="my-container"
          :delay="0"
          @hidden="clearPopOver"
        >
          <template #title>
            Image Description
          </template>

          <div>
            <b-form-group
              label="Title"
              label-for="title"
            >
              <b-input-group>
                <b-form-input
                  v-model="image.title"
                  id-="title"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Description"
              label-for="description"
            >
              <b-input-group>
                <b-form-textarea
                  id="description"
                  v-model="image.description"
                  placeholder="Enter maximum 100 characters"
                  rows="4"
                  :state="image.description.length <= 100"
                />
              </b-input-group>
            </b-form-group>

            <div class="button-wrapper text-right">
              <b-button
                variant="danger"
                class="mr-1"
                @click="close"
              >
                Close
              </b-button>
              <b-button
                variant="primary"
                :disabled="isLoading"
                @click="saveImageDetails"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                Save
              </b-button>
            </div>
          </div>
        </b-popover>
      </div>
    </div>
    <div
      v-if="tourId"
      class="text-right my-1 pt-1 border-top"
    >
      <b-button
        variant="outline-primary"
        :to="`/tour-register/step-5/${tourId}`"
      >
        Itinerary
        <feather-icon
          icon="ArrowRightIcon"
          size="12"
        />
      </b-button>
    </div>
    <ImageViewer
      :category-type="categoryType"
      :is-loading="isBtnLoading"
      :images-to-show="imagesToEdit"
      @uploadImages="uploadImages"
      @clearInput="clearInput"
    />
  </b-card>
</template>

<script>
import draggable from 'vuedraggable'
import api from '@/libs/api'
import helper from '@/mixins/helper'
import tour from '@/mixins/tour'
import ImageViewer from '@/components/common/ImageViewer.vue'

export default {
  name: 'Photos',
  components: {
    ImageViewer,
    draggable,
  },
  mixins: [tour, helper],
  data() {
    return {
      isBtnLoading: false,
      isLoading: false,
      previewPictures: [],
      pictures: [],
      previewFeaturedPicture: null,
      featured_picture: null,
      tourId: this.$store.state.tour.property.id,
      categoryType: '',
      imageIndex: '',
      imagesToEdit: [],
      isImageUploaded: false,
      isUploading: false,
      popOverIndex: null,
      image: {
        title: '',
        description: '',
      },
    }
  },
  mounted() {
    this.isTourValid(this.$route.params.id, this.getTourPhotos)
  },
  methods: {
    getTourPhotos() {
      api.getData(`tour/admin_services/tour/${this.$route.params.id}/photos/`, true).then(response => {
        if (response.data.status) {
          this.previewFeaturedPicture = response.data.result.featured_picture
          this.previewPictures = response.data.result.pictures
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          if (response.data.error.code === 400) {
            this.$router.push('/')
          }
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, your request couldn\'t be processed')
      })
    },
    showImageUploader(images, type) {
      if (type === 'general' && images.length > 0) {
        this.imagesToEdit = images
        this.categoryType = type
      } else if (images && type === 'feature') {
        this.imagesToEdit.push(images)
        this.categoryType = type
      }
      this.isUploading = true
      if (this.imagesToEdit.length > 0 && this.isUploading) {
        this.$root.$emit('bv::toggle::collapse', 'image-viewer')
      }
    },
    uploadImages(images, type) {
      this.isBtnLoading = true
      const url = `/tour/admin_services/tour/${this.tourId}/featured_picture/`
      if (type === 'feature') {
        const formData = new FormData()
        formData.append('featured_picture', images[0].file)
        this.callPhotosApi(url, formData)
      } else {
        this.uploadTourPictures(images)
      }
    },
    callPhotosApi(url, data) {
      api.updateData(url, true, data).then(response => {
        if (response.data.status) {
          this.previewFeaturedPicture = response.data.result.featured_picture
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Data updated successfully')
          if (this.isUploading) {
            this.$root.$emit('bv::toggle::collapse', 'image-viewer')
          }
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isBtnLoading = false
      })
    },
    async uploadTourPictures(images) {
      const file = images
      const photos = this.previewPictures
      let maxIndex = photos.length > 0 ? photos.sort((a, b) => (b.p_index - a.p_index))[0].p_index + 1 : 1
      for (let i = 0; i < file.length; i += 1) {
        const formData = new FormData()
        formData.append('img', file[i].file)
        formData.append('p_index', maxIndex)
        formData.append('title', file[i].title ? file[i].title : '')
        formData.append('description', file[i].description ? file[i].description : '')
        maxIndex += 1
        // eslint-disable-next-line no-await-in-loop
        const image = await api.postData('tour/admin_services/tour_picture/', true, formData)
        if (image.data.status) {
          this.previewPictures.push(image.data.result)
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', JSON.stringify(image.data.error.message))
        }
      }
      this.sendTourPictures()
    },
    sendTourPictures() {
      const payload = { pictures: this.previewPictures.map(p => p.id) }
      const url = `/tour/admin_services/tour/${this.tourId}/picture_update/`
      this.callPhotosApi(url, payload)
    },
    removePicture(image, index) {
      const formData = {
        id: image.id,
        p_index: image.p_index,
      }
      this.isUploading = false
      api.updateData(`tour/admin_services/tour_picture/${image.id}/?delete_old=true`, true, formData).then(response => {
        if (response.data.status) {
          this.previewPictures.splice(index, 1)
          this.sendTourPictures()
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      })
    },
    clearInput() {
      this.featured_picture = null
      this.pictures = []
      this.imagesToEdit = []
    },
    openPopover(i) {
      if (!this.popOverIndex) {
        this.popOverIndex = i
        this.image.title = this.previewPictures[i].title ? this.previewPictures[i].title : ''
        this.image.description = this.previewPictures[i].description ? this.previewPictures[i].description : ''
        setTimeout(() => {
          this.$refs.updateImagePopover.$emit('open')
        }, 50)
      }
    },
    saveImageDetails() {
      const { id } = this.previewPictures[this.popOverIndex]
      api.updateData(`/tour/admin_services/tour_picture/${id}/`, true, this.image).then(response => {
        if (response.data.status) {
          this.getTourPhotos()
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Data updated successfully')
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      })
      this.close()
    },
    close() {
      this.$refs.updateImagePopover.$emit('close')
    },
    clearPopOver() {
      this.popOverIndex = null
      this.image.title = ''
      this.image.description = ''
    },
  },
}
</script>

<style lang="scss" scoped>
h4.card-title {
  font-weight: bold;
}
h6.card-subtitle {
  line-height: 1.5rem;
}
.image-placeholder {
  border: 1px dashed #d7d6ee;
  border-radius: 5px;
  margin: 20px 0 0;
  padding: 10px;
  height: auto;
  max-height: 320px;
  overflow-y: auto;
}
.image-wrapper {
  width: 150px;
  height: 150px;
  margin: 0 10px 10px 0;
  position: relative;
  .remove-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #eceaff;
    padding: 5px;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .edit-icon {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: #eceaff;
    padding: 5px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
@media only screen and (max-device-width: 576px) {
  .image-wrapper {
    width: 100%;
  }
}
</style>
